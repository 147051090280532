import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import { ReactComponent as PoweredMedialog } from "./../../Global/images/poweredByMedialog.svg";
import PortugalCertificationLogo from "./../../Global/images/Portugal_Certification_Logo.png";
import Dgert from "./../../Global/images/dgert.png";
import ProfileTOP5 from "./../../Global/images/PROFILE-TOP5.png";

const StyledFooter = styled(NanoFlex)`
  height: auto;
  background-color: ${(props) => props.theme.color.main.blue};
  padding: 32px;
  .footerListWrapper {
    margin-bottom: 32px;
    .footerList {
      width: auto;
      margin-right: 40px;
      .titleList {
        height: auto;
        margin-bottom: 8px;
        h6 {
          color: ${(props) => props.theme.color.main.white};
          text-transform: uppercase;
        }
      }
      .footerItemsList {
        .footerItem {
          height: auto;
          margin-bottom: 4px;
          a {
            text-decoration: none;
            p {
              color: ${(props) => props.theme.color.main.white};
            }
            &:hover {
              p {
                transition: ${(props) => props.theme.transition};
                text-decoration: underline;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .certificatesContainer {
    .imgsWrapper {
      width: auto;

      .logoWrapper {
        margin-right: 16px;
        a {
          text-decoration: none;
          img {
            height: 100px;
            width: auto;
            object-fit: contain;
          }
          .certificationLogo {
            filter: drop-shadow(0px 0px 2px #ffffff6b);
          }
          .profileTopLogo {
            filter: drop-shadow(0px 0px 2px #131313);
          }
          .dgertLogo {
            filter: drop-shadow(0px 0px 2px #131313);
            background-color: #fff;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .footerBottom {
    .asideBottom {
      h6 {
        color: ${(props) => props.theme.color.main.white};
      }
    }
  }

  .whatsappContainer {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 30px;
    right: 30px;
    background-color: rgb(37, 211, 102);
    border-radius: 50px;
    z-index: 1000;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-shadow: rgb(71 71 71) 0px 0px 8px 0px;
    transition: 0.1s ease-in-out;
    svg {
      height: 33px;
      fill: rgb(255, 255, 255);
    }
  }

  .isBottom {
    bottom: 90px;
  }

  /* MEDIA QUERIES */
  @media only screen and (max-width: 380px) {
    .footerListWrapper {
      flex-direction: column;
      .footerList {
        margin-right: 0px;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .certificatesContainer {
      justify-content: center;
      margin-bottom: 16px;
    }
  }
`;

export class Footer extends BaseComponent {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.state = {
      isBottom: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.25, 0.5, 0.75, 1],
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.setState({
          isBottom: entry.isIntersecting,
        });
      });
    }, options);

    this.observer.observe(this.myRef.current);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.observer.disconnect();
  }
  render() {
    return (
      <StyledFooter className="footer" flexDirection="column">
        <NanoFlex className="footerListWrapper" justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
          {/* LIST TO REPEAT */}
          <NanoFlex className="footerList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="titleList" justifyContent="flex-start">
              <Type.h6>
                <b>{this.translation.navBar.aboutUs}</b>
              </Type.h6>
            </NanoFlex>
            <NanoFlex className="footerItemsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/">
                  <Type.p>{this.translation.global.elevus}</Type.p>
                </Link>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/services">
                  <Type.p>{this.translation.navBar.services}</Type.p>
                </Link>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/careers">
                  <Type.p>{this.translation.navBar.careers}</Type.p>
                </Link>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* END LIST TO REPEAT */}
          {/* LIST TO REPEAT */}
          <NanoFlex className="footerList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="titleList" justifyContent="flex-start">
              <Type.h6>
                <b>{this.translation.navBar.services}</b>
              </Type.h6>
            </NanoFlex>
            <NanoFlex className="footerItemsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/services/">
                  <Type.p>{this.translation.global.recruitment}</Type.p>
                </Link>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/services/outsourcing">
                  <Type.p>{this.translation.global.outsourcing}</Type.p>
                </Link>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/services/consultingRH">
                  <Type.p>{this.translation.global.consultingRh}</Type.p>
                </Link>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/services/formation">
                  <Type.p>{this.translation.global.formation}</Type.p>
                </Link>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="/services/assessmentCenter">
                  <Type.p>{this.translation.global.asssessmentCenter}</Type.p>
                </Link>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* END LIST TO REPEAT */}
          {/* LIST TO REPEAT */}
          <NanoFlex className="footerList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="titleList" justifyContent="flex-start">
              <Type.h6>
                <b>{this.translation.navBar.contacts}</b>
              </Type.h6>
            </NanoFlex>
            <NanoFlex className="footerItemsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <Link to="#contacts">
                  <Type.h5>{this.translation.navBar.contactUs}</Type.h5>
                </Link>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <a href={this.translation.global.facebookLink} rel="noopener noreferrer" target="_blank">
                  <Type.p>Facebook</Type.p>
                </a>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <a href={this.translation.global.instagramLink} rel="noopener noreferrer" target="_blank">
                  <Type.p>Instagram</Type.p>
                </a>
              </NanoFlex>
              <NanoFlex className="footerItem" justifyContent="flex-start">
                <a href="https://www.linkedin.com/company/elevus-lisboa/" rel="noopener noreferrer" target="_blank">
                  <Type.p>LinkedIn</Type.p>
                </a>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          {/* END LIST TO REPEAT */}
        </NanoFlex>
        <NanoFlex className="certificatesContainer" justifyContent="flex-start">
          <NanoFlex className="imgsWrapper" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="logoWrapper">
              <a href="https://www.greatplacetowork.pt/empresas-certificadas/elevus" rel="noopener noreferrer" target="_blank">
                <img className="certificationLogo" src={PortugalCertificationLogo} alt="Portugal Certification" />
              </a>
            </NanoFlex>
            <NanoFlex className="logoWrapper">
              <a href="https://scoring.pt/empresas/elevus/" rel="noopener noreferrer" target="_blank">
                <img className="profileTopLogo" src={ProfileTOP5} alt="Profile TOP5" />
              </a>
            </NanoFlex>
            <NanoFlex className="logoWrapper">
              <a href="#" rel="noopener noreferrer" target="_blank">
                <img className="dgertLogo" src={Dgert} alt="Dgert" />
              </a>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="footerBottom" justifyContent="space-between" alignItems="flex-end">
          <NanoFlex className="asideBottom" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Type.h6>© 2022 ELEVUS</Type.h6>
          </NanoFlex>
          <NanoFlex ref={this.myRef} className="asideBottom" justifyContent="flex-end" alignItems="flex-end">
            <a href="https://www.medialog.pt/" rel="noopener noreferrer" target="_blank">
              <PoweredMedialog />
            </a>
          </NanoFlex>
        </NanoFlex>
        <div>
          <a className={this.state.isBottom ? "whatsappContainer isBottom" : "whatsappContainer"} href={`https://wa.me/${window.env.PHONE_NUMBER}`} target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
          </a>
        </div>
      </StyledFooter>
    );
  }
}
